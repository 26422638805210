import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <nav className="navbar navbar-expand-sm navbar-light bg-light mb-3">
      <div className="container">
        <Link to="/" className="navbar-brand">
          <img src={`/assets/logo.png`} alt="Logo Space Sheep" height="40" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarCollapse">
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link to="/programming" className="nav-link">Programming</Link>
            </li>
            <li className="nav-item">
              <Link to="/essays" className="nav-link">Essays</Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;