import React from "react";

const Header = () => {
  return (
    <footer id="main-footer" className="py-4 bg-dark text-white text-center">
      Copyright &copy; <span className="year"></span> Space Sheep
    </footer>
  );
};

export default Header;
