import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import postsData from "../data/posts.json";
import 'katex/dist/katex.min.css';

const Post = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);

  useEffect(() => {
    const fetchedPost = postsData.find((p) => p.slug === slug);
    if (fetchedPost) {
      setPost(fetchedPost);
    } else {
      console.error("Post not found:", slug);
    }
  }, [slug]);

  if (!post) {
    return <div>No post</div>;
  }

  const imagePath = `/assets/posts/${post.id}/${post.image}`;

  const components = {
    img: ({ node, ...props }) => (
      <img {...props} className="post-image" />
    )
  };

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-lg-8">
          <article className="blog-post">
            <header>
              <h2 className="blog-post-title mb-1">{post.title}</h2>
              <div className="blog-post-meta mb-1">
                <b>
                  {post.writer} - {post.date}
                </b>
              </div>
            </header>
            <figure className="mb-4">
              <img
                className="img-fluid rounded"
                src={imagePath}
                alt={post.title}
              />
            </figure>
            <ReactMarkdown 
              remarkPlugins={[remarkMath]} 
              rehypePlugins={[rehypeKatex]}
              components={components}>
              {post.text}
            </ReactMarkdown>
            {post.github && (
              <p>
                This code can be found here:{" "}
                <a href={post.github}>{post.github}</a>.
              </p>
            )}
          </article>
        </div>
        <div className="col-lg-4">
          <div className="card text-center about-card">
            <h5 className="card-header">About me</h5>
            <img
              src={`/assets/profile.jpg`}
              className="rounded-circle about-img"
              alt="heitor hellou profile image"
            />
            <div className="card-body">
              <p className="card-text">
                Software developer from Brazil. Proeficient with C++, Haxe, JavaScript and C#.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Post;