import React, { useState } from "react";
import { Link } from "react-router-dom";

const CardPost = ({ post }) => {
  const imagePath = `/assets/posts/${post.id}/${post.image}`;;
  const linkTo = `/post/${post.slug}`;

  return (
    <div className="card h-100">
      <img src={imagePath} alt={post.title} className="img-fluid rounded" />
      <div className="card-body">
        <div className="card-body d-flex flex-column">
          <strong className="d-inline-block mb-2 text-success h5">
            #{post.category}
          </strong>
          <Link
            to={linkTo}
            className="mb-2 h3 text-decoration-none text-dark post-title"
          >
            {post.title}
          </Link>
          <div className="mb-1 text-muted card-text">
            {post.writer} - {post.date}
          </div>
          <p className="card-text">{post.description}</p>
        </div>
      </div>
    </div>
  );
};

export default CardPost;
