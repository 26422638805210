import React, { useState } from "react";
import { Link } from "react-router-dom";

const HomePost = ({ post }) => {
  const imagePath = `/assets/posts/${post.id}/${post.image}`;;
  const linkTo = `/post/${post.slug}`;

  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="row">
          <div className="col-md-4 order-1 order-md-2">
            <img
              src={imagePath}
              alt={post.title}
              className="img-fluid rounded"
            />
          </div>
          <div className="col-md-8 d-flex flex-column order-2 order-md-1">
            <h5 className="card-title">{post.title}</h5>
            <strong className="d-inline-block mb-2 text-success h5">
              #{post.category}
            </strong>
            <p className="card-text">{post.description}</p>
            <div className="mt-auto">
              <Link to={linkTo} className="btn btn-primary">
                Read more
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePost;
