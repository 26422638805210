import React, { useState, useEffect } from "react";
import CardPost from "../components/CardPost";
import postsData from '../data/posts.json';

const Programming = () => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 6;

  useEffect(() => {
    const programmingPosts = postsData.filter(post => post.category === "programming").reverse();
    setPosts(programmingPosts);
  }, []);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(posts.length / postsPerPage);

  return (
    <main className="container mt-4">
      <div className="row row-cols-1 row-cols-md-3 g-4 mb-4">
        {currentPosts.map(post => (
          <div className="col" key={post.slug}>
            <CardPost post={post} />
          </div>
        ))}
      </div>
      <nav>
        <ul className="pagination justify-content-center">
          {[...Array(totalPages)].map((_, i) => (
            <li key={i} className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}>
              <button onClick={() => paginate(i + 1)} className="page-link">
                {i + 1}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    </main>
  );
};

export default Programming;
