import React, { useState, useEffect } from "react";
import HomePost from "../components/HomePost";
import postsData from "../data/posts.json";

const Home = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    // Fetch posts from JSON file (dynamically imported)
    const fetchPosts = async () => {
      try {
        const data = postsData; // Directly import postsData
        const lastPosts = data.slice(-6).reverse();
        setPosts(lastPosts);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };

    fetchPosts();
  }, []);

  return (
    <main className="container mt-4 mb-2">
      <div className="row featurette">
        <div className="col-lg-8">
          <div className="content">
            {posts.map((post) => (
              <HomePost key={post.slug} post={post} />
            ))}
          </div>
        </div>
        <div className="col-lg-4">
          <div className="card text-center about-card">
            <h5 className="card-header">About me</h5>
            <img
              src={`/assets/profile.jpg`}
              className="rounded-circle about-img"
              alt="heitor hellou profile image"
            />
            <div className="card-body">
              <p className="card-text">
                Software developer from Brazil. Proeficient with C++, Haxe, JavaScript and C#.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;
